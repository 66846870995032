export function openModal(id) {
  const modal = document.getElementById(id);
  modal.classList.remove("invisible");
  const bg = modal.querySelector(".modal-bg");
  const closeButton = modal.querySelector(".modal-close");
  const content = modal.querySelector(".modal-content");
  bg.classList.add("opacity-100");
  content.classList.add("opacity-100", "translate-y-0", "sm:scale-100");

  [bg, closeButton].forEach((elm) =>
    elm?.addEventListener("click", function () {
      modal.classList.add("invisible");
      bg.classList.remove("opacity-100");
      content.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
    })
  );
}
